import React from "react"

import Contact from "../components/contact"
import Layout from "../components/layout"
import SEO from "../components/seo"

function ContactPage() {
  return (
    <Layout>
      <SEO
        keywords={[
          `contact`,
          `sincerely eden`,
          `challenge`,
          `about sincerely eden`
        ]}
        title="Sincerely Eden | Get in touch with Sincerely Eden."
        description="Leave a message, and we will get back to you by phone or email promptly."
      />
      <Contact />
    </Layout>
  )
}

export default ContactPage
